.user-card {
    background-color:#29333c !important;
    padding: 20px;
    border-radius: 25px;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.user-card:hover {
    cursor: pointer;
    box-shadow: 0 8px 26px 0 rgba(0, 0, 0, 0.30);
}

.writer-stats {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
}

.admin-container {
    padding-bottom: 20px !important;
}

.admin-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-bottom: 2px;
}

.prompt-option-title {
    font-size: 1.5rem !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    color: black;
    line-height: 1.2;
}