.user-navbar {
    background-color: #29333C;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.user-left-info {
    display: flex;
    flex-direction: row;
}

.user-right-info {
    display: flex;
    flex-direction: column;
}

.tiny-plus {
    height: 15px;
    width: 15px;
    margin-left: 5px;
}

.user-navbar-names {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}

.user-navbar-fullname {
    font-size: 25px;
    font-weight: bold;
    color: white;
    padding-bottom:  10px;
}

.user-navbar-username {
    font-size: 20px;
    color: white;
    padding-bottom:  10px;
}

.user-navbar-image {
    height: 125px;
    width: 125px;
    border-radius: 50%;
}

.user-navbar-lower {
    background-color: #2a4052;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
}

.user-navbar-lower-tab {
    padding-bottom: 10px;
    padding-top: 15px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 20px;
}

.user-navbar-lower-tab:hover {
    cursor: pointer;
    background-color: #4d7ba1;
}

.feed-list {
    padding-left: 15px;
}
.feed-list-item {
    padding-bottom: 15px;
}
.blue-highlight {
    color: #79AAF7;
}
/* .blue-highlight:hover {
    cursor: pointer;
    text-decoration: underline;
} */

.blue-underline {
    border-bottom: 5px solid #79AAF7;
}

.user-information-side {
    background-color: lightgray;
    padding: 10px;
    border-radius: 20px;
}

.user-image-container {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.full-name {
    font-size: 25px;
    font-weight: medium;
    text-align: center;
    color: white;
    padding-bottom:  20px;
}

.full-name-userpage {
    font-size: 25px;
    font-weight: medium;
    text-align: center;
    font-weight: bold;
    color: black;
    padding-bottom:  20px;
}

.user-name {
    font-size: 25px;
    text-align: center;
    color: white;
    padding-bottom:  20px;
}

.user-name-userpage {
    font-size: 25px;
    text-align: center;
    color: black;
    padding-bottom:  20px;
}

.user-description {
    font-size: 20px;
    color: #4e4e4e;
    padding-bottom:  20px;
    padding-left: 10px;
    padding-right: 10px;
}

.social-media-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-style: solid;
    border-color: gray;
}

.user-info-container {
    background-color: #2a4052;
    padding: 20px;
}

.user-info-section-title {
    font-size: 16px;
    padding-bottom: 10px;
    color: gray;
    font-style: italic;
}

.about-row {
    padding-bottom: 20px;
}

.one-link-row {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
}

.profile-link {
    padding-left: 10px;
    padding-top: 5px;
    overflow: hidden;
}

.profile-link:hover {
    text-decoration: underline;
}

.user-stats-row {
    /* padding-bottom: 10px;
    padding-top: 10px; */
    margin-bottom: 10px;
    margin-top: 10px;
}

.large-stat {
    margin-right: 15px;
}

.total-stats-title {
    font-weight: bold;
    font-size: 20px;
    color: white;
}

.black {
    color: black !important;
}

.social-icon {
    width: 40px;
    height: 40px;
    padding: 5px;
}

.social-icon:hover {
    cursor: pointer;
}

.no-stories {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
}

.refresh-users-link {
    color: lightblue;
    text-decoration: underline;
}

.refresh-users-link:hover {
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .user-stats-row {
        width: 100%;
    }
}