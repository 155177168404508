.slider_demo2 {
  margin-bottom: 8px;
}

.slider_demo1 {
  position: relative;
}

.slider_demo1 {
  .single_gallary_item {
    display: block!important;
  }
}
