@import 'fonts';
@import 'colors';
@import 'inheritance';
@import 'mixin';
@import 'responsive';

.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $secondary_bg;
  z-index: 999;
}

.preloader.dark-p{
  background:$primary_bg;
}

.preloader.v4{
  background:$gradient41;
}

.preloader > div {
  width: 300px;
  height: 300px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

/* NO BORDER SPINNER */

.nb-spinner {
  width: 75px;
  height: 75px;
  margin: 0;
  background: transparent;
  border-top: 4px solid $white;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

/* BORDER TOP SPINNER */
.bt-spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid #222;
  border-top-color: $white;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

/* DOUBLE BORDER SPINNER */
.db-spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid #222;
  border-top-color: #009688;
  border-bottom-color: #009688;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}




/* DOUBLE SPINNER */

.double-spinner {
  position: relative;
}

.dbl-spinner {
  position: absolute;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid transparent;
  border-top: 4px solid #222;
  border-left: 4px solid #222;
  -webkit-animation: 2s spin linear infinite;
  animation: 2s spin linear infinite;
}

.dbl-spinner--2 {
  border: 4px solid transparent;
  border-right: 4px solid #009688;
  border-bottom: 4px solid #009688;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}


@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

