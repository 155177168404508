.lower-sticky-footer {
    width: 40px;
    height: 40px;
    text-align: center;
    background: #79AAF7;
    color: #fff;
    position: fixed;
    z-index: 2;
    bottom: 9%;
    left: 6%;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    display: block;
}

.sticky-footer {
    height: 60px;
    width: 100%;
    text-align: center;
    background: #79AAF7;
    color: #fff;
    position: fixed;
    z-index: 2;
    bottom: 0%;
}

.sticky-footer-objects {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.sticky-footer-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    background: #79AAF7;
    width: 100%;
}

.join-anchor-text {
    margin-right: 10px;
    margin-top: 7px;
}

.create-anchor-button {
    background-color: #233340 !important;
}

@media only screen and (max-width: 1200px) {
    .lower-sticky-footer {
        display: none;
    }
    .join-anchor-text {
        padding-bottom: 5px;
    }
    .sticky-footer-row {
        padding-bottom: 20px;
        background-color: #79AAF7;
    }
}