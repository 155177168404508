.background-grey-edit {
  background-color: lightgray;
    color: #131516 !important;
}

.story-title-container {
    padding-bottom: 20px;
}

.story-page-message {
    color: gray;
    padding-top: 10px;
    padding-bottom: 10px;
}

.story-title-input {
    margin-top: 10px;
    background-color: lightgray;
    border-style: solid;
    font-size: 20px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.story-description-input {
    margin-top: 10px;
    background-color: lightgray;
    border-style: solid;
    font-size: 16px !important;
    padding:10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tag-options {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
}

.tag-option {
    border-style: solid;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tag-option:hover {
    cursor: pointer;
    background-color: #304759;
    color:white;
}

.tag-highlighted {
    background-color: #304759;
    color:white;
}

.make-name-public {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;
}

.story-question-text {
    font-size: 20px;
}

.story-text-input {
    margin-top: 10px;
    background-color: lightgray;
    border-style: solid;
    font-size: 16px !important;
    padding:10px;
    min-height: 100px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.submit-buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.save-for-later {
    margin-right: 10px;
    background-color: lightgray !important;
    color: black !important;
}

.button-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


/* SLIDER STUFF BELOW */
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: black;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #79AAF7;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #79AAF7;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
/* SLIDER STUFF ABOVE */

.uploaded-image {
  margin-top: 10px;
}

.write-story-prompt-info {
  margin-top: 10px;
  font-size: 20px;
}

.edit-prompt-link {
  margin-left: 10px;
  color: #1f8aff !important;
}
.edit-prompt-link:hover {
  cursor: pointer;
}

.remove-prompt-link {
  color: #1f8aff !important;
}
.remove-prompt-link:hover {
  cursor: pointer;
}

.no-prompt-selected {
  margin-top: 10px;
  margin-bottom: 5px;
}

.prompt-selector {
  padding: 5px;
  overflow:clip;
}