.newsletter-container {
    background-color: #4c718f;
    color: black;
    padding: 20px;
    border-radius: 25px;
    margin: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: 100%;
}

.newsletter-container:hover {
    cursor: pointer;
    box-shadow: none;
    color: #79AAF7;
}

.newsletter-title {
    font-size: 25px;
    font-weight: bold;
    text-decoration: underline;
    padding-bottom: 10px;
}