.center-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.account-tabs {
    background-color: #233340;
    margin-bottom: 50px;
}

.account-tab-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.account-tab {
    display: flex;
    flex-direction: row;
    padding: 20px;
    width: 100%;
}

.account-tab:hover {
    background-color: #344c5f;
    cursor: pointer;
}

.tab-clicked {
    background-color: #344c5f;
    cursor: pointer;
}

.account-tab-title {
    font-weight: bold;
    font-size: 20px;
}

.account-tab-border {
    height: 2px;
    width: 100%;
    background-color: white;
}

/* ACCOUNT INFO STUFF BELOW */

.account-info-container {
    background-color: #304759;
    padding-left: 50px;
    padding-right: 50px;
}

.account-field-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 20px;
    padding: 20px;
}

.account-field-value {
    padding-left: 5px;
    padding-right: 5px;
}

.small-pen {
    width: 30px;
    height: 30px;
}

.small-pen:hover {
    cursor: pointer;
}

.profile-image-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 20px;
    padding: 20px;
}

.profile-image {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.profile-image-large {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.profile-image-text{
    text-align: center;
}

.edit-image-text {
    text-align: center;
    color: #79AAF7;
    font-size: 25px;
}

.edit-image-text:hover {
    cursor: pointer;
}

.account-info-input {
    margin-left: 5px;
    margin-right: 5px;
    height: 100%;
}

.social-media-container {
    background-color: #304759 !important;
    border: 0px !important;
}

.view-social-media-button {
    padding: 0px !important;
}

.small-plus {
    width: 40px;
    height: 40px;
}

.save-social-media-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
    padding-bottom: 20px;
}

.social-media-saved {
    font-size: 20px;
    padding: 20px;
    display: none;
}

/* ACCOUNT INFO STUFF ABOVE */


/* ACCOUNT SETTINGS STUFF BELOW */

.settings-field-container {
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.settings-field {
    font-size: 20px;
}

.custom-control-input:hover {
    cursor:pointer !important;
}

.logout-button {
    margin-bottom: 30px;
}

/* ACCOUNT SETTING STUFF ABOVE */


/* ACCOUNT STORIES STUFF BELOW */

.plus-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #233340;
    padding: 20px;
    margin: 40px;
    border-radius: 25px;
}

.plus-button:hover {
    background-color: #79AAF7;
    cursor:pointer;
}

.plus-button div {
    font-size: 25px;
    color: white;
    padding-right: 15px;
    padding-top: 7px;
}

.add-story-plus {
    height: 40px;
    width: 40px;
}

.user-stories-button-row {
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.story-saved {
    margin-top: 20px;
    padding: 15px;
    background-color: #233340;
    border-radius: 25px;
}

/* ACCOUNT STORIES STUFF ABOVE */

.experiencing-issues-question {
    font-size: 20px;
    padding-top: 20px;
}

.notification-dot {
    background-color: #79AAF7;
    border-radius: 50%;
    padding: 8px;
    box-shadow: 0 4px 8px 0 rgba(102, 102, 102, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notification-dot-preview {
    background-color: #79AAF7;
    border-radius: 50%;
    padding: 11px;
    box-shadow: 0 4px 8px 0 rgba(102, 102, 102, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notification-dot-preview-container {
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 0px;
}

.notification-dot-container {
    padding-top: 5px;
    padding-left: 15px;
}

.floating-notification {
    margin-left: -10px;
    margin-top: -10px;
    z-index: 2000; 
    position: fixed;
}

.notifications-container {
    background-color: #233340;
    margin-bottom: 15px;
}

.notification-dot-container-table {
    padding-top: 4px;
    padding-right: 10px;
}

.individual-notification {
    padding: 10px;
    color: white;
    border-top: 1px solid #79AAF7;
    border-bottom: 1px solid #79AAF7;
    display: flex;
    flex-direction: row;
}

.individual-notification:hover {
    color: white;
    cursor: pointer;
    background-color: #344c5f;
}

.clear-all {
    margin-bottom: 30px;
    cursor: pointer;
}

.sub-account-nav-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #233340;
    border-radius: 20px;
}

.sub-account-nav-title .prev-icon {
    width: 30px;
    height: 30px;
}

.none {
    display: none;
}

@media only screen and (max-width: 500px) {
    .account-info-container {
        padding-left: 0px;
        padding-right: 0px;
    }
}