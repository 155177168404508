@import 'fonts';
@import 'colors';
@import 'inheritance';
@import 'mixin';
@import 'responsive';

.bg4 {
  background: $bg4;
}


.theme-4 {

  .container {
    max-width: 1250px;
  }

  .footer_area3 .logo a {
    margin-bottom: 15px;
  }


  a {
    &:hover {
      color: $v4meta_color;
    }
  }

  .page_category h4,
  li.page_category {
    color: $v4meta_color;
  }

  .welcome_list .slick-track {
    margin: 0 0;
  }

  .welcome_list .slick-list {
    padding: 0 30px 0 0 !important;
  }

  .welcome_list .slick-list {

    margin-right: 30px;
  }

  .logo4 {
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 20px;
    width: 250px;
    height: 150px;
    background: $gradient41;
    text-align: center;
  }

  .v3datentemp > div {
    width: 45%;
    float: left;
  }

  .search4 {
    color: white;
    cursor: pointer;
    background: $gradient41;
    display: inline-block;
    padding: 21px;
  }

  .widget-title {
    padding-left: 40px;
  }

  .col-lg-4.align-self-center.fix_width_social {
    max-width: 26%;
  }

  .welcome_list {
    .single_news_list {
      flex: 1;
      margin: 0 1px;
    }
  }


  .widget-title {
    &:before {
      position: absolute;
      content: "";
      height: 5px;
      width: 30px;
      background: $gradient41;
      left: 0;
      top: 50%;
      margin-top: -2.5px;
      z-index: -1;

    }
  }

  .social4 {
    li {

      a {
        width: 50px;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        background: $border4;
        font-size: 15px;
        color: $body_color;
        position: relative;
        z-index: 2;
        overflow: hidden;
        margin-left: 2px;
        @include transition(all .3s);

        &:before {
          position: absolute;
          content: "";
          height: 100%;
          width: 100%;
          left: -100%;
          z-index: -1;
          background: $gradient41;
          @include transition(all .3s);
        }

        &:hover {
          color: $white;

          &:before {
            left: 0;
            @include transition(all .3s);
          }
        }
      }
    }
  }


  .slider_arrow4 {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    height: 160px;
    width: 30px;
    line-height: 160px;
    text-align: center;
    color: #000;
    font-size: 21px;
    z-index: 99;
    transition: all .3s;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
  }

  .slider_arrow4.arrow_right {
    left: auto;
    right: 0;
  }

  .slider_arrow4:hover {
    background: $gradient41;
    color: #fff;
    transition: all .3s;
  }


  a.showmore, button.showmore, input.showmore {
    background: $bg4readmore_bg;
    color: $v4meta_color;
    border: none;
  }

  .date4 {
    text-align: center;
  }

  .date4 h5 {
    font-size: 18px;
  }

  .date4 h5 span {
    display: block;
    font-size: 14px;
    font-weight: $wightRegular;
    margin-top: 5px;
    opacity: .6;
  }

  .logo_area {
    padding: 0;
  }

  .welcome_txt {
    .title_meta {
      font-weight: $wightMedium;
      color: $white;
      position: relative;
      z-index: 2;
      padding-left: 15px;

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 5px;
        background: $gradient41;
        left: 0;
        top: 50%;
        margin-top: -10px;
      }
    }

    h1 {
      color: $white;
      font-size: 44px;
      text-decoration: underline;
    }
  }

  .welcome4_area_wrap {
    position: relative;
    z-index: 1
  }

  .welcome4_area {
    padding: 250px 0 350px;
    position: relative;
    z-index: 2
  }


  .welcome_news_list {
    .row {
      > div {
        padding: 1px;
      }
    }

    .single_news_list {
      background: $primary_bg;
      padding: 25px;
      height: 160px;
      @include transition(all .3s);
      position: relative;
      z-index: 2;

      &:after {
        position: absolute;
        content: "";
        height: 5px;
        width: 0;
        background: $gradient41;
        bottom: 0;
        @include transition(all .3s);
        left: 0;
      }

      &:hover {
        &:after {
          width: 100%;
          @include transition(all .3s);
        }
      }

      a {
        color: #fff;
        @include transition(all .3s);
        font-weight: $wightRegular;
        font-size: 16px;
        line-height: 23px;
        margin-top: 10px;
      }

      h4 {
        font-weight: $wightRegular;
      }

      p {
        color: #fff;
        @include transition(all .3s);
        font-weight: $wightMedium;

        span {
          color: #fff;
          @include transition(all .3s);
        }
      }

      &:hover {
        background: $white;

        a {
          color: $body_color;
          @include transition(all .3s);
        }

        p {
          color: $body_color;
          @include transition(all .3s);

          span {
            color: $v4meta_color;
            @include transition(all .3s);
          }
        }
      }
    }
  }


  .single_post_text {
    .meta {
      color: $v4meta_color;
      text-transform: uppercase;
      line-height: 17px;
      font-size: 12px;
      margin-bottom: 5px;
      position: relative;
      z-index: 2;

      span {
        color: $meta_date_color;
        text-transform: capitalize;
      }
    }
  }


  .single_post_text {
    a {
      color: $body_color;
      font-weight: $wightMedium
    }
  }


  .wrapper_welcome {
    position: relative;
    z-index: 2;
  }

  .menu4.home4menu {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 99;
  }

  .welcome_list {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
  }

  .single_news_list {
    min-height: 160px;
    background: $body_color;
    padding: 20px;
    color: $white;
    position: relative;
    z-index: 2;
    @include transition(all .3s);
    cursor: pointer;
  }

  .single_news_list h4 {
    color: $white;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-top: 8px;
    @include transition(color .3s);
  }

  .single_news_list p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    @include transition(color .3s);
  }

  .single_news_list:before {
    position: absolute;
    content: "";
    height: 4px;
    width: 0;
    background: $gradient41;
    bottom: 0;
    left: 0;
    @include transition(all .3s);
  }

  .single_news_list.active {
    background: $bg_white;
    @include transition(all .3s);
  }

  .single_news_list.active p,
  .single_news_list.active h4 {
    color: $body_color;
    @include transition(all .3s);
  }

  .single_news_list.active span {
    color: $v4meta_color;
    @include transition(all .3s);
  }


  .single_news_list.active:before {
    width: 100%;
    @include transition(all .3s);
  }


  .single_post {
    &:hover {
      .single_post_text {
        a {
          color: $v4meta_color;
          @include transition(all .3s)
        }
      }
    }
  }

  .user3 i {
    color: #17222B;
    opacity: .6;
  }


  .v3datentemp {
    display: flex;
    align-items: center;
  }

  .trancarousel_area {
    .nav_style1 .navBtns {
      position: absolute;
      right: -20%;
      top: 50%;
      margin-top: -15px;

      .navBtn {
        height: 30px;
        width: 30px;
        line-height: 30px;
        background: #ECEDEE;
        color: #718594;
        border: none;

        &:last-child {
          margin-left: 3px;
        }
      }
    }

    p.trand {
      float: left;
      margin-right: 10px;
      padding: 12px 20px;
      background: $primary_bg;
      font-size: 14px;
      font-weight: 500;
      color: $white;
      line-height: 17px;
    }

    .trancarousel_item {
      padding: 12px;
      font-size: 14px;
      line-height: 17px;
      opacity: .85;

      a {
        color: $body_color;
        opacity: .85;
        word-break: break-word;
      }

      p {
        color: $body_color;
        opacity: .85;
        word-break: break-word;
      }
    }
  }


  .lang-3 {
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      a {
        display: inline-block;
        padding: 7px;
      }

      position: relative;
      z-index: 2;
      @include transition(all .3s);

      &:hover {
        ul {
          visibility: visible;
          opacity: 1;
          top: 40px;
          @include transition(all .3s);

        }
      }

      ul {
        position: absolute;
        right: 0;
        top: auto;
        text-align: left;
        background: $bg_white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        visibility: hidden;
        top: 70px;
        padding: 10px 0;
        @include transition(all .3s);
        width: 120px;
        z-index: 999;
        opacity: 0;

        li {
          a {
            padding: 5px 15px;
          }
        }
      }

      a {
        opacity: .75;
        color: #17222B;

        i {
          opacity: 1;
        }
      }
    }
  }

  .topbar {
    margin-top: -10px;
    margin-bottom: 3px;
  }

  .social4 {
    position: relative;
    z-index: 1;
  }

  .social4 {
    ul {
      margin: 0;
      padding: 0;
    }
  }

  .single_post_text {
    .meta2 {
      margin-bottom: 0;

      a {
        font-size: 12px;
        text-transform: capitalize;
        position: relative;
        z-index: 2;
        line-height: 17px;
        margin-bottom: 5px;

        &:first-child {
          color: $v4meta_color;
        }

        &:last-child {
          margin-left: 20px;
          color: $body_color;
          opacity: .75;

          &:before {
            position: absolute;
            content: "|";
            height: 20px;
            width: 10px;
            left: -18px;
            top: 50%;
            margin-top: -10px;
            line-height: 20px;
            color: $body_color;
            opacity: .3;
            text-align: center;
          }
        }
      }
    }
  }


  .single_post_text {
    .meta5 {
      margin-bottom: 0;

      a {
        font-size: 12px;
        text-transform: capitalize;
        position: relative;
        z-index: 2;
        line-height: 17px;
        margin-bottom: 5px;
        color: $white;

        &:last-child {
          margin-left: 20px;
          font-weight: 400;

          &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 10px;
            left: -18px;
            top: 50%;
            background: #000;
            margin-top: -10px;
            line-height: 20px;
            color: $white;
            text-align: center;
          }
        }
      }
    }
  }


  .single_post_text {
    .meta3 {
      margin-bottom: 0;
      position: relative;
      z-index: 2;

      a {
        font-size: 12px !important;
        position: relative;
        z-index: 2;
        line-height: 14px;
        font-weight: 500;
        margin-bottom: 0;

        &:first-child {
          color: $v4meta_color;
        }

        &:last-child {
          margin-left: 20px;
          color: $body_color;
          opacity: .75;

          &:before {
            position: absolute;
            content: "|";
            height: 20px;
            width: 10px;
            left: -18px;
            top: 50%;
            margin-top: -10px;
            line-height: 20px;
            color: $body_color;
            opacity: .3;
            text-align: center;
          }
        }
      }
    }
  }

  .single_post_text {
    .meta {
      margin-bottom: 0;

      a {
        font-size: 14px;
        text-transform: capitalize;
        position: relative;
        z-index: 2;

        &:last-child {
          margin-left: 20px;

          &:before {
            position: absolute;
            content: "|" !important;
            height: 20px;
            width: 10px;
            left: -18px;
            top: 50%;
            margin-top: -10px;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }


  .single_post_text {
    .meta4 {
      margin-top: 0;

      a {
        font-size: 12px !important;
        text-transform: capitalize;
        position: relative;
        z-index: 2;
        line-height: 17px;
        font-weight: 500;
        color: $body_color;
        opacity: .6;
      }
    }
  }


  .type16 {
    padding-left: 110px;
    margin-right: 0;
    min-height: 65px;
    margin-bottom: 0;

    .post_img {
      border-radius: 3px;
      overflow: hidden;
      max-height: 65px;
      max-width: 90px;
    }
  }

  .scroll_bar::-webkit-scrollbar {
    width: 2px;
  }

  .scroll_bar::-webkit-scrollbar-thumb {
    background-color: $scroll_bar_color;
    outline: 1px solid $scroll_bar_color;
  }

  .business_carousel {
    border-radius: 5px;
    position: relative;
  }

  .share_meta4 {
    ul {
      margin: 0;
      padding: 0;

      li {
        a {
          font-size: 15px !important;
          font-weight: 400;
          color: $body_color;
          opacity: .4;
        }

        &:nth-child(2) {
          margin-left: 7px;
        }
      }
    }
  }

  .nav_style4 {
    .owl-nav {
      > div {
        position: absolute;
        top: 50%;
        left: -21px;
        z-index: 1;
        height: 42px;
        width: 42px;
        cursor: pointer;
        background: $bg_white;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        margin-top: -21px;
        border: 1px solid $border_black_color;

        &:hover {
          background: $gradient41;
          color: $white;
        }
      }

      > div.owl-next {
        position: absolute;
        top: 50%;
        left: auto;
        right: -21px;
        height: 42px;
        width: 42px;
        background: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        border: 1px solid $border_black_color;

        &:hover {
          background: $gradient41;
          color: $white;
        }
      }
    }
  }

  .meta.before {
    position: relative;
    z-index: 2;
    padding-left: 25px;
    font-weight: $wightMedium;
  }

  .meta.before {
    &:before {
      position: absolute;
      content: "";
      height: 3px;
      width: 20px;
      background: $gradient41;
      left: 0;
      top: 50%;
      margin-top: -1.5px;
    }
  }

  .single_international {
    a {
      font-size: 20px;
      color: $body_color;
      font-weight: $wightMedium;
      padding: 15px 0;
    }

    img {
      border-radius: 3px;
    }
  }


  .shadow7 {
    box-shadow: $shadow7;
  }

  .bg4 {
    background: $bg4;
  }

  .finance {
    .single_post_text {
      padding: 20px;
    }
  }

  span.tranding {
    background: $gradient41;
  }

  span.batch3.date {
    background: $gradient41;
    position: absolute;
    left: 0;
    top: 20px;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 18px 12px;
    color: #fff;
    font-size: 12px;
    border-radius: 0 30px 30px 0;
  }

  .popular_items.scroll_bar {
    max-height: 430px;
    overflow-y: scroll;
  }

  .type22 {
    padding-left: 85px;
    margin-right: 0;
    min-height: 50px;
    margin-bottom: 0;

    .post_img {
      height: 50px;
      width: 75px;
      min-height: 50px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 3px;
      }
    }

  }

  .post_type12 {
    .post_img {
      width: 42%;
    }

    .single_post_text {

      a {
        font-size: 24px;
        line-height: 29px;
      }

      width: 58%;

      p {
        font-size: 17px;
        font-weight: 300;
        line-height: 27px;
      }
    }
  }

  .image_carousel {
    position: relative;

    .post_img {
      img {
        border-radius: 7px;
        height: 452px;
        width: 100%;
      }
    }
  }

  .footer {
    padding: 80px 0 0 0;

    .single_contact3 {
      display: inline-block;
      width: 32.33%;
      position: relative;
      z-index: 2;
      padding-left: 20px;

      h6 {
        font-size: 18px;
        font-weight: $wightRegular;
        margin-bottom: 5px;
        position: relative;
        z-index: 2;

        &:before {
          position: absolute;
          content: "";
          height: 6px;
          width: 6px;
          background: $v4meta_color;
          left: -15px;
          top: 50%;
          margin-top: -3px;
        }
      }

      p {
        color: $body_color;
        font-size: 15px;
        font-weight: $wightMedium;
      }

      a {
        color: $body_color;
        font-size: 15px;
        font-weight: $wightMedium;
      }
    }

    .more_newss {
      padding-left: 20px;
      border-left: 1px solid $more_news_border
    }

    .single_footer_nav {
      ul {
        li {
          a {
            color: $body_color;
          }
        }
      }
    }

    .single_fcontact {
      position: relative;
      z-index: 2;
      padding-left: 30px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: $body_color;
      }

      .fcicon {
        height: 30px;
        width: 30px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -15px;
        line-height: 30px;
      }
    }

    .widget-title2 {
      font-size: $widget_title_font_size;
      color: $body_color;
      font-weight: $wightRegular;
      margin-bottom: 30px;
    }


    .twitter_feeds {
      .single_twitter_feed {
        position: relative;
        z-index: 2;
        padding-left: 30px;
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }

        h6 {
          color: $body_color;

          span {
            color: $secondary_color;
          }
        }

        p {
          font-size: $small_font_size;
          color: $body_color;
          margin-top: 3px;
        }

        .twitter_feed_icon {
          height: 20px;
          width: 20px;
          color: $secondary_color;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }


    .single_more_news {
      position: relative;
      z-index: 2;

      &:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        background: $bg_white;
        border-radius: 50%;
        left: -27.5px;
        top: 0;
      }

      &:after {
        position: absolute;
        content: "";
        height: 5px;
        width: 5px;
        background: $v4meta_color2;
        border-radius: 50%;
        left: -22.5px;
        top: 5.5px;
      }
    }


    .extra_newss {
      .single_extra_news {
        margin-bottom: 15px;
        padding-bottom: 30px;
        padding-right: 60px;
        position: relative;
        z-index: 2;

        &:nth-child(6) {
          margin-bottom: 0;
          padding-bottom: 0;
        }

        p {
          color: $secondary_color;
          font-size: $extra_small;

          span {
            color: $footer_dark_color;
          }
        }

        a {
          color: $body_color;

        }

        span.news_counter {
          color: $footer_dark_color;
          height: 40px;
          display: inline-block;
          width: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 36px;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -25px;
        }
      }

      .single_fcontact {
        position: relative;
        z-index: 2;
        padding-left: 30px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: $body_color;
        }

        .fcicon {
          height: 30px;
          width: 30px;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -15px;
          line-height: 30px;
        }
      }


    }

    .single_more_news {
      a {
        color: $body_color;
        font-size: 18px;
        font-weight: $wightMedium;
      }

      p.meta {
        color: $v4meta_color;
        font-weight: $wightMedium;
        font-size: 12px;
      }

      p {
        font-style: 15px;
        font-weight: $wightLight;
        color: $border_second_color;
        margin-top: 10px;
      }
    }


    .copyright {
      background: $theme3_bg;
      margin-top: 30px;
      padding: 15px 0;

      p {
        color: $footer_dark_color;

      }

      ul {
        li {
          a {
            color: $body_color;
            padding: 0 10px;

            &:hover {
              color: $body_color;
            }
          }
        }
      }
    }
  }


  a.single_social {
    display: inline-block;
    padding: 13px;
    font-size: 18px;
    font-weight: $wightMedium;
    position: relative;
    z-index: 2;
    padding-left: 35px;
    background: $facebook_bg;
    margin: 4px 3px;
    color: $white;
    overflow: hidden;
    width: 104px;
    line-height: 12px;
    border-radius: 4px;

    span.follow_icon {
      position: absolute;
      left: 0;
      font-size: 12px;
      height: 100%;
      width: 30px;
      text-align: center;
      top: 0;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $facebook_bg2;
    }

    span.icon_text {
      display: block;
      line-height: 10px;
      font-size: 11px;
      margin-top: 5px;
    }

  }


  a.social_twitter {
    background: $twitter_bg;

    span.follow_icon {
      background: $twitter_bg2;
    }
  }


  a.social_youtube {
    background: $youtube_bg;

    span.follow_icon {
      background: $youtube_bg2;
    }
  }


  a.social_instagram {
    background: $instagram_bg;

    span.follow_icon {
      background: $instagram_bg2;
    }
  }


  a.social_vimeo {
    background: $vimeo_bg;

    span.follow_icon {
      background: $vimeo_bg2;
    }
  }


  a.social_medium {
    background: $medium_bg;

    span.follow_icon {
      background: $medium_bg2;
    }
  }

  .post_type6 {
    .single_post_text {
      padding: 25px;

      a {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }


  ul.like_cm {
    margin: 0;
    margin-top: 10px;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 20px;
      position: relative;
      z-index: 2;

      &:after {
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        background: $border_solid_color;
        right: -12px;
        top: 50%;
        margin-top: -6px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      a {
        font-weight: 500;
        font-size: 12px !important;
        color: #747A80;
        margin: 0;
        padding: 0;

        i {
          color: $v4meta_color2;
          margin-right: 4px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .widgets_small ul.like_cm {

    margin-top: 0;
  }

  .post_img {
    img {
      border-radius: 3px;
    }
  }

  .type18 {
    .post_img {
      img {

        border-radius: 5px;
      }
    }

    .single_post_text {
      a {
        font-size: 18px;
        line-height: 22px;
      }

      p {
        font-size: 15px;
        font-weight: 300;
        opacity: .85;
        line-height: 23px;
      }
    }
  }

  .single_post_text.py0 {
    padding: 20px 0 0 0;
  }


  .topbar {

    li.tranding {
      background: $primary_bg;
      color: $white
    }

  }

  .category4 {
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          background: $category4_bg;
          display: block;
          border-radius: 3px;
          padding: 15px 15px 15px 20px;
          font-size: 20px;
          font-weight: $wightRegular;
          color: $body_color;
          position: relative;
          z-index: 2;

          i {
            position: absolute;
            right: 0;
            font-size: 16px;
            color: transparent;
            height: 40px;
            width: 40px;
            text-align: center;
            line-height: 40px;
            top: 50%;
            margin-top: -20px;
          }

          &:hover {
            color: $white;
            font-weight: $wightMedium;
            background: $gradient41;

            i {
              color: $white;
            }
          }

        }
      }
    }
  }


  .tatal_video4 {
    padding: 30px;
    height: 635px;
    position: relative;
    z-index: 3;

    .video4_content {
      bottom: 30px;
      left: 30px;
      position: absolute;

      a {
        font-size: 44px;
        line-height: 50px;
        font-weight: $wightBold;
        color: $white;
      }
    }

    .video4_video {
      display: inline-block;
      padding-left: 70px;
      position: relative;
      z-index: 2;
      margin-top: 20px;

      h6 {
        color: $white;

        span {
          display: block;
          font-weight: $wightRegular;
          margin-top: 5px;
        }
      }

      .video4_icon {
        position: absolute;
        left: 0;
        top: 50%;
        height: 56px;
        width: 56px;
        text-align: center;
        line-height: 56px;
        margin-top: -28px;
        background: $gradient41;
        border-radius: 50%;
        color: $white;

      }
    }

  }

  .video4_list {
    a {
      font-size: 20px;
      color: $body_color;
      font-weight: 400;
    }

    h4 {
      font-size: 20px;
      font-weight: 400;
    }

    background: $white;
    position: relative;
    max-height: 635px;
    overflow-y: scroll;

    &:before {
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 13px solid red;
      border-bottom: 7px solid transparent;
      position: absolute;
      content: "";
      left: -12px;
      top: 60px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .video_list_author {
      position: relative;
      z-index: 2;
      padding-left: 35px;
      margin-bottom: 10px;

      i {
        position: absolute;
        left: 0;
        height: 30px;
        width: 30px;
        text-align: center;
        line-height: 30px;
        color: $videoAuthori;
        font-size: 20px;
      }

      h6 {
        font-size: 12px;
        text-transform: uppercase;
        color: $tranding_bg;

        span {
          display: block;
          margin-top: 5px;
          text-transform: capitalize;
          color: $videoAuthor_span;
          font-weight: 400;
        }

      }

    }

  }

  .tatal_videos4 .row > div {
    padding: 0;
  }

  .tatal_videos4 > .row {
    border-radius: 5px;
    overflow: hidden;
  }


  .science_news {
    background: $white;
  }


  .book_mark {
    position: relative;
    z-index: 2;
    padding-left: 45px;
    margin-top: 15px;
    display: inline-block;

    .bookmark_icon {
      height: 30px;
      width: 30px;
      position: absolute;
      text-align: center;
      line-height: 30px;
      left: 0;
      color: $v4meta_color;
      top: 50%;
      margin-top: -15px;
      border-radius: 50%;
      border: 1px solid $v4meta_color;
    }

    h6 {
      font-size: 14px;

      span {
        display: block;
        font-weight: $wightRegular;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }

  .science_carousel {
    position: relative;

    .single_post {
      padding-right: 10px;
    }
  }

  .science_carousel_items {
    p.post-p {
      font-size: 15px;
      font-weight: $wightLight;
    }

    .single_post_text {
      a {
        font-size: 20px;
      }
    }
  }

  p.meta.meta_style4 {
    position: relative;
    z-index: 2;
    padding-left: 12px;
  }

  p.meta.meta_style4:after {
    position: absolute;
    content: "";
    height: 18px;
    width: 3px;
    background: $gradient41;
    left: 0;
    top: 50%;
    margin-top: -9px;
  }


  .dots_style1 {
    .owl-dots {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;

      > span {
        height: 10px;
        width: 10px;
        background: $dots_color;
        display: inline-flex;
        margin: 2px;
        border-radius: 50%;
      }

      span.swiper-pagination-bullet-active {
        background: $gradient41;
      }
    }
  }


  .news_letter.news_letter4 {
    background: $newsletter4_bg;

    p {
      color: $white;
      font-size: 17px;
      font-weight: $wightLight;
    }
  }


  .signup_form {
    form {
      background: $input3_bg;
      position: relative;
      z-index: 2;
      border-radius: 5px;
      overflow: hidden;

      .signup {
        padding: 15px;
        width: calc(100% - 104px);
        margin: 0;
        background: $white;
      }

      .cbtn {
        background: $gradient41;
        position: absolute;
        right: 0;
        top: 0px;
        padding: 15px 20px;
      }
    }
  }


  .tranding_bar > div {
    display: inline-block;
  }

  .tranding_top {
    float: left;
    background: red;
    padding: 5px 20px;
    margin-right: 15px;
  }

  .top_carousel {
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }

  .tranding_bar > div {
    display: flex !important;
    align-items: center;
  }

  .top_carousel {
    padding: 5px 20px 5px 0;
  }


  button.cbtn4,
  input.cbtn4,
  a.cbtn4 {
    @extend %button4;
    border: none;
  }


  .single_comment {
    .replay {
      p {
        color: $v4meta_color !important;
      }
    }
  }

  .next_prv_single3 {
    &:hover {
      background: $v4meta_color;

    }
  }


  .author_social {
    li {

      a {
        height: 40px;
        line-height: 40px;
        width: 40px;
        text-align: center;
        background: $border4;
        font-size: 15px;
        color: $body_color;
        position: relative;
        z-index: 2;
        overflow: hidden;
        @include transition(all .3s);

        &:before {
          position: absolute;
          content: "";
          height: 100%;
          width: 100%;
          left: -100%;
          z-index: -1;
          background: $gradient41;
          @include transition(all .3s);
        }

        &:hover {
          color: $white;
          background: transparent;

          &:before {
            left: 0;
            @include transition(all .3s);
          }
        }
      }
    }
  }

  .widget_tab ul li a.active {
    background: $gradient41 !important;
  }


  .widget_tab.v4inner ul li a {
    line-height: 12px;
    padding: 12px 21px;
  }


  .post_img.number {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 3px solid #E7E8E9;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    top: 15%;
    margin-top: 0;

    h2 {
      font-size: 36px;
      line-height: 53px;
      color: #D1D3D5;
    }
  }


  .tab4 {
    ul.nav.nav-tabs {
      li {
        a {
          font-size: 24px;
          font-weight: 500;
          color: $tab4_color;
          padding: 12px;
          position: relative;
          z-index: 2;
          @include transition(all .3s);

          &:after {
            position: absolute;
            content: "";
            height: 3px;
            width: 0;
            background: $gradient41;
            left: 0;
            bottom: 0;
            @include transition(all .3s);
          }

          &:hover {
            color: $body_color;
            @include transition(all .3s);
          }
        }

        a.active {
          color: $body_color !important;
          @include transition(all .3s);

          &:after {
            width: 100%;
            @include transition(all .3s);
          }
        }
      }
    }

    .single_post_text {
      a {
        font-size: 20px;
        line-height: 28px;
      }

      .meta_col {
        p {
          font-size: 12px;
          color: $tab4_color;
        }
      }
    }
  }

  .type16 ul.like_cm li a {
    font-size: 12px !important;
  }

  .inernational4 {
    .meta {
      font-size: 12px;
      color: $v4meta_color;

      span {
        color: $footer_dark_color;
      }
    }
  }


  /*================================
      RESPONSIVE CSS

  */


  /*================================
      HEIGH LARGE DEVICE

  */

  @media screen and (min-width: extraLarge) {

  }


  /*================================
      EXTRA LARGE DEVICE

  */

  @media screen and (max-width: extraLarge) {


  }


  /*================================
      EXTRA LARGE DEVICE

  */

  @media screen and (max-width: $small__laptop) and (max-width: $laptop) {
    .col-lg-4.align-self-center.fix_width_social {
      max-width: 33%;
    }
    body {
      overflow-x: hidden;
    }

    .tab4 ul.nav.nav-tabs li a {
      font-size: 18px;
    }
    .tab4 .single_post_text a {
      font-size: 14px;
      line-height: 21px;
    }

    .widgets_small .single_post_text a {
      line-height: 19px;
      font-size: 13px;
    }
    .single_post_text .meta3 a {
      font-size: 10px !important;
    }
    .temp_wap p {
      font-size: 10px;
    }
    .v3datentemp > div {
      width: 50%;
      float: left;
    }
    .logo_area {
      //overflow-x: hidden;
    }


    .download_btn.v4 .download_btn_group a.app_btn {

      padding: 12px 10px 12px 30px;
    }

    .download_btn.v4 .download_btn_group a.app_btn i {
      left: 7px;
      font-size: 20px;
      height: 20px;
      width: 20px;
      line-height: 20px;
      margin-top: -10px;
    }

  }


  /*================================
      SMALL LEPTOP DEVICE

  */

  @media screen and (min-width: $large) and (max-width: small__leptop) {


  }


  /*================================
      MEDIUM DEVICE

  */

  @media screen and (min-width: $Medium) and (max-width: lage) {

  }


  /*================================
      LARGE MOBILE DEVICE

  */

  @media screen and (max-width: $large) {
    .logo4 {
      margin: 0 auto;
      margin-bottom: 20px;
    }

    .topbar .tranding_bar li {
      font-size: 12px;
    }
  }


  /*================================
      MOBILE LANDSCAPE DEVICE

  */

  @media screen and (max-width: $Medium) {


    .topbar {
      text-align: center;
      margin-top: 20px;
    }


    .v3datentemp {
      text-align: center !important;
    }

    .social4.text-right {
      text-align: center !important;
      margin-bottom: 20px;
    }

    .top_date_social.text-right {
      text-align: center !important;
    }

    .v3datentemp > div {
      width: 100%;
      float: unset;
      margin-bottom: 10px;
      text-align: center !important;
    }

    .welcome_txt h1 {
      font-size: 26px;
    }
    .tatal_video4 .video4_content a {
      font-size: 24px;
      line-height: 28px;
    }

    .col-lg-4.align-self-center.fix_width_social {
      max-width: 100%;
      margin-top: 30px;
    }
    .welcome4_area {
      padding: 200px 0 120px;
    }

    .nav_style4 .owl-nav {
      > div.owl-next{
        right: -10px;
      }
      > div.owl-prev{
        left: -10px;
      }
    }

  }


  /*================================
   MOBILE PORTRAIT DEVICE

  */
  @media screen and (max-width: $mobile_layout) {

    .single_post_text {
      .meta3 {
        margin-bottom: 0;
        position: relative;
        z-index: 2;

        a {
          font-size: 12px !important;
          position: relative;
          z-index: 2;
          line-height: 14px;
          font-weight: 500;
          margin-bottom: 0;

          &:first-child {
            color: $v4meta_color;
          }

          &:last-child {
            margin-left: 0;
            color: $body_color;
            opacity: .75;

            &:before {
              position: absolute;
              content: "|";
              height: 20px;
              width: 10px;
              left: -18px;
              top: 50%;
              margin-top: -10px;
              line-height: 20px;
              color: $body_color;
              opacity: .3;
              text-align: center;
              display: none;
            }
          }
        }
      }
    }
    .post_type12 .post_img {
      width: 100%;
    }
    .post_type12 .single_post_text {
      width: 100%;
    }
    .business3.padding30.white_bg.border-radious5.mb30 {
      padding: 20px 0;
    }
    .single_post.mb30.type18 {
      text-align: center;
    }
    .science_mid {
      img {
        margin: 20px 0;
      }
    }
    .book_mark {
      text-align: left;
    }

    .tab4 ul.nav.nav-tabs li a {
      font-size: 16px;
    }
    .widgets_type4 {
      padding-left: 0;

      .post_img.number {
        display: none;
      }
    }

  }

  .search_form4 input[type="submit"] {
    background: linear-gradient(90deg, #FF5555, #AB50EF);
    color: #fff;
    padding: 15px 35px;
  }

}

@media screen and (min-width: 320px) and (max-width: 960px) {
  .theme-4 .post_type12 .single_post_text {
    width: 100%;
  }
  .theme-4 .post_type12 .post_img {
    width: 100%;
  }
}