.comments-section {
    padding-top: 20px;
}

.comments-title {
    text-align: left;
}

.comment-box-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.comment-box-row input {
    height: 50px;
}

.user-image {
    margin: 10px;
    height: 64px;
    width: 64px;
}

.thin-bar {
    width: 100%;
    height: 2px;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

.comment-button-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.form-control {
    overflow:visible;
}

.other-comments {
    padding-top: 20px;
}

.commenter-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main-commenter-image-container {
    margin: 10px;
}

.main-commenter-image {
    height: 64px;
    width: 64px;
    border-radius: 50%;
}

.commenter-image {
    margin: 5px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.commenter-col {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding-left: 10px;
}

.time-from-comment {
    text-align: left;
    font-style: italic;
    font-weight:lighter;
}

.commenter-name {
    text-align: left;
}

.comment {
    text-align: left;
    font-size: 20px;
}

.comment-text-container {
    padding-left: 60px;
    padding-right: 10px;
}

.reply-link {
    color: #1091FF;
    text-align: right;
}

.reply-link:hover {
    cursor: pointer;
}

.sub-comments {
    padding-top: 15px;
    padding-left: 15px;
    background-color: rgb(41, 51, 60);
}

@media (max-width: 414px) {
    .comment-text-container {
        padding-left: 0px;
        padding-right: 0px;
    }
}