.favorite-button {
    margin-bottom: 20px;
}

.author-bio-container {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.author-bio-image {
    margin: 10px;
    height: 120px;
    width: 120px;
    border-radius: 50%;
}
.author-bio-name-desc {
    margin: 10px;
    margin-top: 30px;
}
.author-bio-name {
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: medium;
}
.author-bio-name b:hover {
    cursor: pointer;
}

.hover-story-title-container {
    padding-top: 62px;
    padding-bottom: 5px;
    position: fixed;
    width: 100%;
    background-color: #304759;
    text-align: center;
    font-size: 18px;
    z-index: 2;
    color: #79AAF7;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

/* SHARE BUTTONS STUFF BELOW */
.share-buttons-container {
    text-align: center;
    border-radius: 50px;
    position: fixed;
    padding-left: 75px;
    display: flex;
    flex-direction: column;
    line-height: 2.75;
}

.favorite-icon {
    height: 50px;
    width: 50px;
    cursor: pointer;
    -webkit-filter: drop-shadow(1px 1px 1px #666666);
}

.favorite-icon:hover {
    -webkit-filter: drop-shadow(2px 2px 2px #666666);
}

.medium-sm-logo {
    height: 35px;
    width: 35px;
    cursor: pointer;
}

.horizontal-share-buttons {
    text-align: center;
    border-radius: 50px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    line-height: 3;
    justify-content: center;
}

.horizontal-share-buttons .medium-sm-logo {
    margin-right: 20px;
}

.horizontal-share-buttons .share-text {
    font-size: 20px;
    margin-right: 20px;
}

@media only screen and (max-width: 1000px) {
    .share-buttons-container {
        padding-left: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .share-buttons-container {
        display:none;
    }
}

.share-icon {
    text-align: center;
    border-radius: 50px;
    position: fixed;
    z-index: 9998;
    bottom: 10%;  /* Account for anchor? */
    right: 20%;
    display: block;
}

.share-img {
    width: 60px;
    height: 60px;
}

@media only screen and (min-width: 767px) {
    .share-icon{
        display:none;
    }
}

.link-copied {
    display: none;
}
/* SHARE BUTTONS STUFF ABOVE */

.story-not-found-container {
    margin-top: 100px;
    text-align: center;
    height: auto;
    min-height: 77%;
}

.article-content br
{   content: "A" !important;
    display: block !important;
    margin-bottom: 30px !important;
}
.story-container {
    margin: 10px;
    padding-top: 30px;
}

.story-page-author-name {
    padding-top: 10px;
    padding-left: 10px;
}

.story-page-author-name:hover {
    text-decoration: underline;
}

p {
    padding-bottom: 30px;
}

.background-grey {
    background-color: lightgray;
    color: #131516 !important;
    margin-top: 85px;
    padding-top: 20px;
    border-radius: 20px;
}

.pad-right-15 {
    padding-right: 15px;
}

.story-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.story-title {
    font-size: 2rem !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    color: black;
    line-height: 1.2;
}

.article-content {
    font-size: 18px;
    line-height: 1.5;
    padding-bottom: 20px;
}

.article-content div {
    padding-top: 20px;
}

.flex-row-together {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.pad-15-right {
    padding-right: 15px;
}

/* START Small icon shit */

.tag-icon-individual {
  margin-top: 8px;
  margin-right: 5px;
  height: 25px;
  width: 25px;
}

.story-tag-individual {
    padding-top: 10px;
}
.story-tag-individual:hover {
    color:#1091FF;
}

.thumb-icon {
    height: 30px;
    width: 30px;
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 15px;
}

.comments-icon {
    height: 30px;
    width: 30px;
    margin-top: 8px;
    margin-right: 5px;
    margin-left: 15px;
}

.comments-button-row:hover {
    cursor: pointer;
    color: #1091FF;
}

.category-tag-individual {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
}
.category-tag-individual:hover {
    cursor: pointer;
    color: #1091FF;
}

/* END small icon shit*/

.mature-content-warning {
    margin-top: 10px;
    padding: 10px;
    background-color: #e31212;
    color: white;
}

.spec-note {
    margin-top: 10px;
    padding: 10px;
    background-color: green;
    color: white;
}

.date-published {
    padding-top: 20px;
    font-size: 16px;
    font-style: italic;
}

.date-published-preview {
    padding-top: 10px;
    font-size: 16px;
    font-style: italic;
    color: #79AAF7;
    font-weight: bold;
}

/* PROGRESS BAR STUFF BELOW */
.meter { 
    height: 1em;
    width: 100%;
    position: fixed;
    bottom: 0%;
    overflow: hidden;
}

.story-progress-bar {
    width: 100%;
    margin-right: 10px;
    color: #79AAF7 !important;
}
/* PROGRESS BAR STUFF ABOVE */

.minutes-to-read {
    color: #737D81;
    padding-top: 10px;
    font-size: 16px;
}

.attached-prompt-title {
    margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
    .story-container {
        padding-top: 0px;
    }
    .author-bio-container {
        flex-wrap: wrap;
        padding-left: 0px;
        padding-right: 0px;
    }
}